<template>
  <v-container
    style="height:100%;"
    class="d-flex flex-column"
  >
    <v-row
      no-gutters
      class="mt-2 mb-4"
    >
      <v-col cols="12">
        <slot name="viewHeader"></slot>
      </v-col>
    </v-row>

    <v-card
      height="100%"
      class="d-flex flex-column"
    >
      <!-- Using border makes this section thinner (not good looking) -->
      <!-- border: 1px solid #E0E0E0 !important; -->
      <div
        style="
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;"
        class="grey lighten-5"
      >
        <h4 class="pa-4">
          <slot name="cardTitle"></slot>
        </h4>
      </div>
      
      <transition mode="out-in">
        <div
          v-if="loaded"
          style="height: 100%; width: 100%;"
        >
          <slot name="cardBody"></slot>
        </div>
        <v-skeleton-loader
          v-else
          type="image"
          elevation="0"
          tile
          height="100%"
        />
      </transition>

    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "ViewCardTemplate",

    props: {
      loaded: {
        type: Boolean,
        required: true,
      }
    }
  }
</script>

<style lang="scss">
.v-skeleton-loader__image {
  height: 100% !important;
}

.v-enter, .v-leave-to {
  opacity: 0;
}

.v-enter-active, .v-leave-active{
  transition: all 125ms ease-out;
}
</style>